import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { loadStripe } from "@stripe/stripe-js";
import { Route, Routes } from "react-router-dom";
import { productsList, stripeSecret } from './components/database';
import { updatePageTitle } from './utilities/titleHelper';
import { useLocation } from 'react-router-dom';
import "./styles/App.css";
import About from "./components/About";
import Cart from './components/store/Cart';
import Checkout from './components/checkout/Checkout';
import Contact from './components/Contact';
import Footer from "./components/Footer";
import Landing from "./components/landing/Landing";
// import MobileCart from './components/navigation/MobileCart';
import Nav from "./components/navigation/Nav";
import Privacy from "./components/privacyterms/Privacy";
import ProductPage from './components/store/ProductPage';
import Return from './components/checkout/Return';
import ScrollToTop from './components/ScrollToTop';
import Shop from "./components/store/Shop";
import Terms from "./components/privacyterms/Terms";
import store from './components/store/Store';

// Load Stripe outside of a component's render to avoid recreating the Stripe object on every render
const stripePromise = loadStripe(stripeSecret);
// Initializes ReactGA-4 for Google Analytics
ReactGA.initialize('G-EKGR6GXP7B');
ReactGA.event('session_start');

function App() {
  const location = useLocation();

  // Tracks Google Analytics page views
  useEffect(() => {
    const pathname = location.pathname;  // Get the current pathname
    updatePageTitle(pathname);           // Update document title
    ReactGA.send({ hitType: "pageview", page: pathname }); // Send pageview to Google Analytics
  }, [location.pathname]);  // This runs whenever the pathname changes

  return (
    <Provider store={store}>
      {/* <BrowserRouter> */}
      <ScrollToTop />
      <Nav />
      <Routes>
        <Route path='/about' element={<About />} />
        <Route path='/cart' element={<Cart />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/product/:sku' element={<ProductPage productsList={productsList} />} />
        <Route path='/' element={<Landing />} />
        <Route path='/privacy' element={<Privacy />} />
        <Route path='/shop' element={<Shop productsList={productsList} />} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/checkout' element={<Checkout stripePromise={stripePromise} />} />
        <Route path='/return' element={<Return />} />
      </Routes>
      <Footer />
      {/* <MobileCart /> */}
      {/* </BrowserRouter> */}
    </Provider>
  );
}

export default App;
