import React, { useState } from "react";
import ReactGA from 'react-ga4';
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from '@stripe/stripe-js';

interface CheckoutFormProps {
	clientSecret: string | null;
	total: number;
	stateCode: string | null;
	discountId: string;
}

export default function PaymentForm({ clientSecret, total, stateCode, discountId }: CheckoutFormProps) {
	const stripe = useStripe();
	const elements = useElements();

	const [message, setMessage] = useState<string | null>(null);
	const [isLoading, setIsLoading] = useState(false);

	const setPaymentIntent = () => {
		if (!stripe || !clientSecret || !stateCode) {
			return;
		}
		stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
			if (!paymentIntent) {
				setMessage("Failed to retrieve payment information.");
				return;
			}
			console.log("Payment intent linked to " + clientSecret);
			switch (paymentIntent.status) {
				case "requires_payment_method":
					setMessage(null); // Don't show any message yet
					break;
				case "requires_confirmation":
					setMessage("Your payment needs to be confirmed.");
					break;
				case "requires_action":
					setMessage("Further action is required to complete the payment.");
					break;
				case "processing":
					setMessage("Your payment is processing.");
					break;
				case "succeeded":
					setMessage("Payment succeeded!");
					break;
				case "canceled":
					setMessage("Payment was canceled.");
					break;
				default:
					if (paymentIntent.last_payment_error) {
						setMessage("Something went wrong. Please try again.");
					}
					break;
			}
		});
	}

	const trackPurchase = () => {
		ReactGA.event('purchase', {
			transaction_id: clientSecret,
			value: total,
			coupon_used: discountId
		});
	}

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		if (!stripe || !elements) {
			return;
		}
		setIsLoading(true);
		setPaymentIntent();
		if (!clientSecret) {
			setMessage("Failed to retrieve payment intent. Please try again.");
			setIsLoading(false);
			return;
		}
		trackPurchase();
		elements.submit();
		const { error } = await stripe.confirmPayment({
			elements,
			clientSecret,
			confirmParams: {
				return_url: `${window.location.origin}/return`,
			},
		});
		if (error?.type === "card_error" || error?.type === "validation_error") {
			setMessage(error.message ?? "An error occurred. Please try again.");
		} else {
			setMessage("An unexpected error occurred.");
		}
		setIsLoading(false);
	};

	const paymentElementOptions: StripePaymentElementOptions = {
		layout: {
			type: "tabs",
		},
	};

	return (
		<form id='payment-form' className="mt-3 mb-3" onSubmit={handleSubmit}>
			<h3 className="fw-bolder">Payment</h3>
			<PaymentElement
				id='payment-element'
				options={paymentElementOptions}
			/>
			<button
				disabled={isLoading || !stripe || !elements}
				id='submit'
				className="btn btn-primary btn-lg px-4 me-sm-3"
			>
				<span id='button-text'>
					{isLoading ? (
						<div
							className='spinner'
							id='spinner'></div>
					) : (
						`Pay now ${total ? `($${(total / 100).toFixed(2)})` : ""}` // Display amount in dollars
					)}
				</span>
			</button>
			{message && <div id='payment-message'>{message}</div>}
		</form>
	);
}