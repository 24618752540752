import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { clearCart } from "../store/CartSlice";

const Return = () => {
  const [status, setStatus] = useState<string | null>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const redirectStatus = urlParams.get('redirect_status');

    // Use the paymentIntentId to fetch session data or directly use redirectStatus
    if (redirectStatus === 'succeeded') {
      setStatus('complete');
      dispatch(clearCart());
    } else {
      setStatus('open');
    }

  }, [dispatch]);

  if (status === 'open') {
    return <Navigate to="/checkout" />;
  }

  if (status === 'complete') {
    return (
      <section id="success">
        <div className="container">
          <div className="about-heading-content">
            <div className="row">
              <div className="col-xl-9 col-lg-10 mx-auto">
                <div className="bg-white rounded p-5 text-center">
                  <h2 className="fw-bolder">Thank You!</h2>
                  <p>
                    Your order has been completed. You will receive a confirmation email when your order is processed. Thank you so much for your business!
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return null;
}

export default Return;
