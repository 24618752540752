import React, { useEffect } from 'react';

interface SizeDropdownProps {
    setSizeSelected: (size: string) => void;
    sizes: string[];
}

export default function SizeDropdown({ setSizeSelected, sizes }: SizeDropdownProps) {

    // Set the default size to the first item in the sizes array
    useEffect(() => {
        if (sizes.length > 0) {
            setSizeSelected(sizes[0]);
        }
    }, [sizes, setSizeSelected]);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSizeSelected(event.target.value);
    };

    return (
        <div>
            <label htmlFor="size">Select: </label>
            <select id="size" name="size" className="size-dropdown" onChange={handleChange}>
                {sizes.map((size: string) => (
                    <option value={size}>{size}</option>
                ))}
            </select>
        </div>
    );
}
