import React from 'react';
import { AddressElement } from '@stripe/react-stripe-js';

interface AddressFormProps {
    setStateCode: React.Dispatch<React.SetStateAction<string>>;
    setSalesTax: React.Dispatch<React.SetStateAction<number>>;
}

const AddressForm = ({ setStateCode, setSalesTax }: AddressFormProps) => {

    const handleAddressChange = async (event: any) => {
        const { value } = event;
        if (value && value.address) {
            const state = value.address.state;

            setStateCode(state);

            // Apply logic based on the state
            if (state === 'IN') {
                setSalesTax(0.07); // 7% Indiana sales tax
            } else {
                setSalesTax(0); // No sales tax for out-of-state customers
            }
        }
    }

    return (
        <div className='mt-3 mb-3'>
            <h3 className="fw-bolder">Shipping Address</h3>
            <AddressElement
                options={{ mode: 'shipping' }}
                onChange={handleAddressChange}
            />
        </div>
    );
};

export default AddressForm;