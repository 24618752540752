import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/logos/footerlogo-heartland-grooming-co.png";
import { paymentOptions } from "./database";

export default function Footer() {

	return (
		<footer className='pt-2 mt-auto secondary-background'>
			<div className='container px-5'>
				<div className='row align-items-center justify-content-between flex-column flex-sm-row'>
					<div className="col-auto">
						{Object.keys(paymentOptions).map(optionKey => {
							const option = paymentOptions[optionKey as keyof typeof paymentOptions];
							return <img src={option.image} alt={option.alt} className="m-1" key={optionKey} />;
						})}
					</div>
					<div className="col-auto m-2">
						<>
							<Link
								className='link-light small m-1'
								to='/about'>
								About
							</Link>
							<Link
								className='link-light small m-1'
								to='/contact'>
								Contact
							</Link>
							<Link
								className='link-light small m-1'
								to='/privacy'>
								Privacy
							</Link>
							<Link
								className='link-light small m-1'
								to='/terms'>
								Terms
							</Link>
						</>
						<ul className="list-inline mb-0 d-flex justify-content-between">
							<li className="list-inline-item me-4">
								<a href="https://www.facebook.com/theheartlandgroomingco/" target="_blank" rel="noopener noreferrer"><i className="bi-facebook fs-3"></i></a>
							</li>
							<li className="list-inline-item me-4">
								<a href="https://www.instagram.com/heartlandgroomingco/" target="_blank" rel="noopener noreferrer"><i className="bi-instagram bi fs-3"></i></a>
							</li>
							<li className="list-inline-item me-4">
								<a href="https://www.threads.net/@heartlandgroomingco" target="_blank" rel="noopener noreferrer"><i className="bi bi-threads fs-3"></i></a>
							</li>
							<li className="list-inline-item me-4">
								<a href="https://twitter.com/HeartlandGCo" target="_blank" rel="noopener noreferrer"><i className="bi-twitter fs-3"></i></a>
							</li>
							<li className="list-inline-item">
								<a href="https://www.youtube.com/@HeartlandGroomingCo" target="_blank" rel="noopener noreferrer"><i className="bi-youtube fs-3"></i></a>
							</li>
						</ul>
					</div>
				</div>
				<div className='row align-items-center justify-content-between flex-column flex-sm-row'>
					<div className='col-auto m-2'>
						<Link to='/'>
							<img
								src={logo}
								className='logo-footer'
								alt="heartland-grooming-co-footer-logo"
							/>
						</Link>
					</div>
					<div className="col-auto">
						<span className="text-white">&copy; Heartland Grooming Co. LLC 2024</span>
					</div>
				</div>
			</div>
		</footer>
	);
}
