import React from "react";
import cedar from "../../images/icons/easterncedar.png";
import sunflower from "../../images/icons/sunflower.png";
import pumpkin from "../../images/icons/pumpkin.png";
import pine from "../../images/icons/pine.png";
import jojoba from "../../images/icons/jojoba.png";

interface KeyIngredientsProps {
	keys: string[];
}

interface Ingredient {
    title: string;
	alt: string;
    description: string;
    image: string; // assuming the image is a string URL or a path
}

interface Ingredients {
    [key: string]: Ingredient;
}

export const ingredientsKeyValue: Ingredients = {
	cedar: {
		title: 'Eastern Red Cedar',
		alt: 'eastern-red-cedar',
		description: 'Cedarwood thrives across the Midwest, making it a locally abundant and sustainable resource. It adds a warm, earthy aroma while also offering antiseptic and anti-inflammatory properties, keeping your beard and skin healthy.',
		image: cedar
	},
	jojoba: {
		title: 'Jojoba Oil',
		alt: 'jojoba-oil',
		description: 'Jojoba may not be a Midwest native, but we won\'t hold that against it. Try as we might, the fact is, Jojoba\'s moisturizing and skin-soothing properties make it one of the most widely used ingredients in beard oil—and for good reason.',
		image: jojoba
	},
	pine: {
		title: 'Scotch Pine',
		alt: 'pine',
		description: 'Scotch Pine is a resilient tree that adapts well to the Midwest, offering a sustainable and locally sourced ingredient. Its fresh, invigorating aroma revitalizes the senses, while its antimicrobial and cleansing properties help keep your beard and skin feeling refreshed and healthy.',
		image: pine
	},
	pumpkin: {
		title: 'Pumpkin Seed Oil',
		alt: 'pumpkin-seed-oil',
		description: 'Pumpkin seed oil might seem a little "basic," but we promise we’re not just here for the fall vibes. As a true Midwestern staple, pumpkin seed oil is rich in vitamins and antioxidants that nourish and protect your beard, leaving it soft, strong, and healthy year-round.',
		image: pumpkin
	},
	sunflower: {
		title: "Sunflower Seed Oil",
		alt: "sunflower-seed-oil",
		description: "Sunflower seed oil, cold-pressed right here in the Midwest, is the perfect base for our formulas. It's lightweight, non-greasy, and deeply nourishes beards, promoting softness and shine.",
		image: sunflower
	}
}

const KeyIngredients: React.FC<KeyIngredientsProps> = ({ keys }) => {
	return (
		<section className="mt-5" id="features">
			<div className="container">
				<div className="text-center mb-4">
					<h2 className="fw-bolder">FEATURED INGREDIENTS</h2>
					<h4 className="section-subheading text-muted">Each ingredient we use has a purpose. Here are some highlights:</h4>
				</div>
				<div className="row text-center">
					{keys.map((key: string) => (
						<div className="col-md-4" key={key}>
							{/* <span className="fa-stack fa-4x"> */}
								<img src={ingredientsKeyValue[key].image} alt={ingredientsKeyValue[key].alt} className="ingredient-icon" />
							{/* </span> */}
							<h4 className="my-3 fw-bolder">{ingredientsKeyValue[key].title}</h4>
							<p className="text-muted">{ingredientsKeyValue[key].description}</p>
						</div>
					))}
				</div>
			</div>
		</section>
	);
}

export default KeyIngredients;