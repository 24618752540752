import React from 'react'
import { LinkAuthenticationElement } from "@stripe/react-stripe-js";

export default function LinkAuthenticationForm() {
    return (
        <div className="mt-3 mb-3">
            <h3 className='fw-bolder'>Contact info</h3>
            <LinkAuthenticationElement
                // Optional prop for prefilling customer information
                onChange={(event) => {
                    // setEmail(event.value.email);
                }}
            />
        </div>
    )
}
