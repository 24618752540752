import React from "react";
// import imageComingSoon from "../../images/logos/coming-soon.jpg";

export default function Spotlight() {
	return (
		<section className="py-5 bg-light  bg-texture-04-light">
			<div className="container px-5 my-5">
				{/* <div className="row gx-5"> */}
					{/* <div className="col-12"> */}
						{/* <img className="img-fluid rounded-3 mb-5" src={imageComingSoon} alt="..."/> */}
					{/* </div> */}
				{/* </div> */}
				<div className="row gx-5 justify-content-center">
					{/* <div className="col-lg-6"> */}
					<div className="col-12">
						<div className="text-center">
							<p className="lead fw-normal text-muted">
							Heartland Grooming Co. was founded in Westfield, IN, with a dedication to creating high-quality grooming products that reflect the values of the Midwest. Each bottle is meticulously handcrafted to ensure that every detail meets our high standards. We are proud to source our ingredients locally whenever possible, bringing a touch of the heartland into every product we make.
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
