import React from "react";
import { Link } from "react-router-dom";

const headingTextOptions = [
    'Crafted from the Heartland',
    'Rooted in Tradition, Made for Today',
    'The Pride of the Midwest'
];

const getHeadingText = () => {
    const randomIndex = Math.floor(Math.random() * headingTextOptions.length);
    const randomFlip = Math.round(Math.random());
    if (randomFlip === 1) {
        return headingTextOptions[randomIndex];
    } else {
        return headingTextOptions[0];
    }
};

export default function Hero() { 
    return (
        <section className="py-5 pb-3 hero-background d-flex align-items-center">
            <div className="container my-5">
                <div className="gx-5 row justify-content-center no-gutters"> {/* removed row */}
                    <div className="col-lg-6">
                        <div className="text-center mb-5">
                            <h1 className="fw-bolder text-white mb-2">
                                {getHeadingText()}
                            </h1>
                            <p className="lead fw-normal text-light">Pure, Natural, Midwestern Beard Care</p>
                            <Link
                                className="btn btn-light btn-lg btn-width bg-gradient"
                                to="/shop"
                            >
                                Shop Now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
