import React from 'react'
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import { selectTotalItems } from '../store/Selectors';

export default function CartForm() {
    const totalItems = useSelector(selectTotalItems);

    return (
        <Link to='/cart'>
            <button className="btn btn-outline-white cart-button" type="button">
                <i className="bi-cart-fill me-1"></i>
                <span className="badge bg-dark text-white ms-1 rounded-pill">{totalItems}</span>
            </button>
        </Link>
    )
}
