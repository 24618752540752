import React from "react";
import { Link } from "react-router-dom";
import CartForm from "./CartForm";

interface NavLinksProps {
    navbarCollapseRef: React.RefObject<HTMLDivElement>;
}

const NavLinks: React.FC<NavLinksProps> = ({ navbarCollapseRef }) => {
    const handleLinkClick = () => {
        if (navbarCollapseRef.current) {
            const bsCollapse = new window.bootstrap.Collapse(navbarCollapseRef.current);
            bsCollapse.toggle();
        }
    };

    return (
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
                <Link className="nav-link" to="/" onClick={handleLinkClick}>
                    Home
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/shop" onClick={handleLinkClick}>
                    Shop
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/about" onClick={handleLinkClick}>
                    About
                </Link>
            </li>
            <li className="nav-item">
                <Link className="nav-link" to="/contact" onClick={handleLinkClick}>
                    Contact
                </Link>
            </li>
            <li>
                <CartForm />
            </li>
        </ul>
    );
};

export default NavLinks;
