import React from "react";
import { Link } from "react-router-dom";
import { getProductBySku, defaultProduct } from "../database";

interface BuyProps {
	sku: string;
}

function Buy({ sku }: BuyProps) {

	const product = getProductBySku(sku) || defaultProduct;

	return (
		<header className=" py-5 secondary-background bg-texture-01-black">
			<div className="container px-5">
				<div className="row gx-5 align-items-center justify-content-center">
					<div className="col-lg-8 col-xl-7 col-xxl-6">
						<div className="my-5 text-center text-xl-start">
							<h2 className="display-5 fw-bolder text-white mb-2">
								MORE THAN JUST A SCENT
							</h2>
							<p className="lead fw-normal text-white mb-4">
								{product.description}
							</p>
							<div className="d-grid gap-3 d-sm-flex justify-content-sm-center justify-content-xl-start">
								<Link
									className="btn btn-primary btn-lg px-4 me-sm-3"
									to={"/product/" + product.sku}
								>
									Buy Now
								</Link>
								<a
									className="btn btn-outline-light btn-lg px-4"
									href="#features"
								>
									Learn More
								</a>
							</div>
						</div>
					</div>
					<div className="col-xl-5 col-xxl-6 d-xl-block text-center order-mobile-first">
						<Link to={"/product/" + product.sku}>
							<img
								className="img-fluid rounded-3 my-5"
								src={product.buyImage}
								alt="..."
							/>
						</Link>
					</div>
				</div>
			</div>
		</header>
	);
}

export default Buy;
