import React from 'react'
import { Product } from '../database';
import { Link } from 'react-router-dom';

interface ShopProps {
  productsList: Product[];
}

const Shop: React.FC<ShopProps> = ({ productsList }) => {
  return (
    <section className="py-5">
      <div className="container px-4 px-lg-5 mt-5">
        <div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center mb-5">
          <h1 className="fw-bolder">Shop</h1>
        </div>
        <div className="row gx-4 gx-lg-5 row-cols-2 row-cols-md-3 row-cols-xl-4 justify-content-center">

          {productsList.map((product: Product) => (
            <>
              {product.sku !== "BO-NS-01" && (
                <div className="col mb-5" id={product.guid}>
                  <div className="card h-100">
                    {/* <!-- Product image--> */}
                    <Link to={"/product/" + product.sku}>
                      <img className="card-img-top" src={product.images[0]} alt="..." />
                    </Link>
                    {/* <!-- Product details--> */}
                    <div className="card-body p-4">
                      <div className="text-center">
                        {/* <!-- Product name--> */}
                        <h5 className="fw-bolder">{product.productType}</h5>
                        <p className="text-muted">{product.name}</p>
                        {/* <!-- Product price--> */}
                        ${product.price}
                      </div>
                    </div>
                    {/* <!-- Product actions--> */}
                    <div className="card-footer p-4 pt-0 border-top-0 bg-transparent">
                      <div className="text-center"><Link className="btn btn-outline-dark mt-auto" to={"/product/" + product.sku}>View Item</Link></div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}


        </div>
      </div>
    </section>
  )
}

export default Shop;
