export const updatePageTitle = (pathname: string, productName: string = ""): void => {
    let title = "Heartland Grooming Co.";
    let pageName = "";

    switch (pathname) {
        case '/':
            pageName = title + " | Home";
            break;
        case '/about':
            pageName = title + " | About";
            break;
        case '/cart':
            pageName = title + " | Cart";
            break;
        case '/contact':
            pageName = title + " | Contact";
            break;
        case '/privacy':
            pageName = title + " | Privacy Policy";
            break;
        case '/shop':
            pageName = title + " | Shop";
            break;
        case '/terms':
            pageName = title + " | Terms of Use";
            break;
        case '/checkout':
            pageName = title + " | Checkout";
            break;
        case '/return':
            pageName = title + " | Confirmation";
            break;
        case '/product/BO-SC-01':
            pageName = title + " | Rustic Pines";
            break;
        case '/product/BO-NS-01':
            pageName = title + " | Unscented Beard Oil";
            break;
        default:
            pageName = title;
            break;
    }

    document.title = pageName;
};
