import React, { useState } from "react";

//update

const Aside: React.FC = () => {
	const [email, setEmail] = useState<string>("");

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmail(event.target.value);
	};

	const handleSubmit = async () => {
		if (email.trim() === "") {
			alert("Please enter a valid email address.");
			return;
		}

		try {
			const response = await fetch('https://script.google.com/macros/s/AKfycbwjLDPT9jrajjmBV_Sj3eM7jlmvkNNFIyl6oaR-qduKfqcXb-By9wm4TPZOqfzBfBFlnA/exec', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
				body: new URLSearchParams({ email }),
			});

			if (response.ok) {
				alert("Thank you for subscribing!");
				setEmail(""); // Clear the input field after successful submission
			} else {
				alert("Something went wrong. Please try again.");
			}
		} catch (error) {
			alert("Error: " + error);
		}
	};

	return (
		<aside className="secondary-background bg-gradient rounded-3 p-4 p-sm-5">
			<div className="d-flex align-items-center justify-content-between flex-column flex-xl-row text-center text-xl-start">
				<div className="mb-4 mb-xl-0">
					<div className="fs-3 fw-bold text-white">
						NEVER MISS AN UPDATE!
					</div>
					<div className="text-white-50">
						Subscribe to stay up on the latest.
					</div>
				</div>
				<div className="ms-xl-4">
					<div className="input-group mb-2">
						<input
							className="form-control"
							type="email"
							placeholder="Email address..."
							aria-label="Email address..."
							aria-describedby="button-newsletter"
							value={email}
							onChange={handleInputChange}
						/>
						<button
							className="btn btn-outline-light"
							id="button-newsletter"
							type="button"
							onClick={handleSubmit}
						>
							Sign up
						</button>
					</div>
					<div className="small text-white-50">
						We care about privacy, and will never share your data.
					</div>
				</div>
			</div>
		</aside>
	);
};

export default Aside;
